var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageHeaderDisplay', {
    attrs: {
      "title": "Admin ",
      "sub-title": "Manage areas of Etainabl.",
      "category": "Admin",
      "category-logo": "fa-user-astronaut",
      "category-route": {
        name: _vm.$route.name
      }
    }
  }), _c('TabView', {
    attrs: {
      "route-name": "admin-view",
      "tabs": _vm.tabs,
      "exact": false
    }
  }, [_c('router-view')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }